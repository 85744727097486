var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FileDetailLayout',{ref:"fileDetailLayout",attrs:{"title":_vm.$t('groups.shared_folder.file_detail_overwrite'),"buttonState":_vm.actionAreaState}},[_c('div',{staticClass:"mt-5 mb-1 px-3"},[_c('div',{staticClass:"file-detail d-flex flex-column"},[_c('div',{staticClass:"file-title word-break"},[_vm._v(" "+_vm._s(_vm.file.title ? _vm.file.title : _vm.file.name)+" ")]),_c('div',{staticClass:"file-name word-break"},[_vm._v(" "+_vm._s(_vm.$t('groups.shared_folder.file_name', { fileName: _vm.file.name }))+" ")]),_c('div',{staticClass:"file-size"},[_vm._v(" "+_vm._s(_vm.$t('groups.shared_folder.file_size', { fileSize: _vm.formatBytes(_vm.file.size) }))+" ")]),(!_vm.isCommonTopic)?_c('div',{staticClass:"save-folder word-break"},[_vm._v(" "+_vm._s(_vm.$t('groups.shared_folder.save_folder', { folder: _vm.file.folder ? _vm.file.folder.name : _vm.$t('groups.unclassified') }))+" ")]):_vm._e(),(_vm.file.topic)?_c('div',{staticClass:"attach-source word-break"},[_vm._v(" "+_vm._s(_vm.$t('groups.shared_folder.attach_source'))+" "),_c('span',{staticClass:"text-primary cursor-pointer",on:{"click":_vm.jumpToParentTopic}},[_vm._v(" "+_vm._s(_vm.file.topic.title)+" ")])]):_vm._e()])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"update-form px-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"up-file mb-2"},[_c('div',{staticClass:"fs-10 fs-xl-12 mb-1 text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")]),_c('div',{staticClass:"file-chooser d-flex justify-content-start align-items-center"},[_c('div',{staticClass:"btn btn-outline-secondary-deep position-relative px-3 flex-fixed flex-center minh---30"},[_c('input',{staticClass:"position-absolute form-control h-100 w-100 top-0 start-0 opacity-0",attrs:{"type":"file","name":"file"},on:{"click":function (e) { return (e.target.value = null); },"change":_vm.onFileChange}}),_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_choose_file'))+" ")]),_c('div',{staticClass:"text-line-clamp-1 ps-3"},[_vm._v(" "+_vm._s(_vm.chooseFileName)+" ")])]),(_vm.fileErrors === _vm.eFileError.EMPTY)?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(_vm.$t('common.message.choose_file'))+" ")]):_vm._e(),_c('div',{staticClass:"fs-8 fs-xl-10 py-1",class:{
              'fs-10 text-danger': _vm.fileErrors === _vm.eFileError.OVER_SIZE
            }},[_vm._v(" "+_vm._s(_vm.fileErrors === _vm.eFileError.OVER_SIZE ? _vm.$t('groups.shared_folder.overwrite_error_msg', { max: _vm.maxFileSize.mb }) : _vm.$t('groups.shared_folder.file_detail_overwrite_msg', { max: _vm.maxFileSize.mb }))+" ")])]),(
            _vm.displayChooseFile &&
              _vm.$store.state.setting.setting.setting_display
                .display_image_video === _vm.ehumbNailsDisplay.DISPLAY_THUMBNAILS
          )?_c('div',{staticClass:"display-file-thumbnail flex-fixed"},[(_vm.getFileFormat() === _vm.eFileFormat.IMAGE)?_c('img',{staticClass:"rounded-1 img-cover cursor-pointer flex-1 w--15 h--15 ms-2",attrs:{"src":_vm.displayChooseFile}}):_c('div',{staticClass:"flex-center w--15 h--15 ms-2"},[_c('i',{class:("fs-35 text-primary " + (_vm.getIconName()))})])]):_vm._e()]),(!_vm.isCommonTopic)?_c('div',{staticClass:"comment"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('groups.comment')))]),_c('div',{staticClass:"text-area"},[_c('ValidationProvider',{attrs:{"rules":{ max: 20000, no_script_tag: true },"name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"form-control",class:{ 'border-danger': errors[0] },attrs:{"name":"comment","rows":"4"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"fs-8 fs-xl-10 py-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('groups.shared_folder.history_log_remind'))+" ")])]):_vm._e()]),_c('div',{staticClass:"action-form flex-center pt-3"},[_c('div',{staticClass:"flex-center"},[_c('button',{staticClass:"btn btn-outline-secondary-deep me-5 px-5",on:{"click":_vm.jumpToFileDetail}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_cancel'))+" ")]),_c('button',{staticClass:"btn btn-primary text-white",on:{"click":function($event){return handleSubmit(_vm.submitOverwriteFile)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_overwrite'))+" ")])])])]}}])}),_c('ModalConfirm',{attrs:{"id":'file-overwrite-modal-confirm',"confirmMess":_vm.$t('common.message.unsave_edit')},on:{"yes":_vm.confirmLeave}}),_c('ModalSuccess',{attrs:{"id":'modal-success-ovewrite',"successMess":_vm.$t('common.message.updated')},on:{"close":_vm.jumpToFileDetail}}),_c('ModalError',{attrs:{"id":'modal-error-ovewrite',"errorMess":_vm.modalMess},on:{"close":_vm.modalAction}}),_c('ModalInfo',{attrs:{"id":'modal-info-ovewrite',"infoMess":_vm.modalMess,"autoClose":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }